import { useEffect, useState } from 'preact/hooks';
import { pick } from 'lodash-es';

import blockEvent from '~/helpers/blockEvent';
import getErrorFromBEResponse from '~/helpers/getErrorFromBEResponse';
import * as issue from '~/helpers/issue';
import useObjectState from '~/helpers/useObjectState';

import Dialog from '~/components/layout/Dialog';
import Grid from '~/components/layout/Grid';
import LinearLayout from '~/components/layout/LinearLayout';
import Text from '~/components/Text';

import Error from '~/prebuilt/generic/Error';
import Input from '~/prebuilt/generic/Input';

import './PasswordDialog.css';

const hasSpecialChar = (str) =>
  /[~$&%[\]{}()<>=*+!#/?@^\\|\-_;:.,'"`]/.test(str);

const getIssues = ({ base, copy }) => {
  const [issues, adder] = issue.blank();
  if (base) {
    const add = adder('base');
    if (!/[a-z]/.test(base)) {
      add('Lowercase characters (a, b, c, ...)');
    }
    if (!/[A-Z]/.test(base)) {
      add('Uppercase characters (A, B, C, ...)');
    }
    if (!/[0-9]/.test(base)) {
      add('Numeric characters (0, 1, 2, ...)');
    }
    if (!hasSpecialChar(base)) {
      add('Special characters ($, #, !, -, @, +, *, ...)');
    }
    if (base.length < 8) {
      add('Minimum 8 characters');
    }
  }

  if (copy) {
    const add = adder('copy');
    if (base !== copy) {
      add('Password does not match');
    }
  }

  return issues;
};

const fields = [
  {
    field: 'base',
    label: 'Set a new Password',
    required: true,
  },
  {
    field: 'copy',
    issueOnBlur: true,
    label: 'Repeat Password',
    noVisibility: true,
    required: true,
  },
];

const parseText = ({ target }) => target.value;

const PasswordDialog = (props) => {
  const { open, onClose, onSave, user } = props;

  const [password, setPassword, updatePassword] = useObjectState({
    base: '',
    copy: '',
  });

  const [error, setError] = useState(undefined);
  const [state, setState] = useState('ready');
  const [focused, setFocused] = useState({});

  const focus = (field) => ({
    onBlur: () => setFocused({ ...focused, [field]: false }),
    onFocus: () => setFocused({ ...focused, [field]: true }),
  });

  const issues = getIssues(password);

  useEffect(() => {
    setError(undefined);
    const filled = Object.values(password).every(Boolean);
    setState(!filled || issue.has(issues) ? 'disabled' : 'ready');
  }, [password]);

  useEffect(() => {
    setPassword({ base: '', copy: '' });
  }, [open]);

  const disabled = state === 'waiting';

  const renderInput = ({
    field,
    issueOnBlur = false,
    label,
    parse = parseText,
    type = 'password',
    ...rest
  }) => (
    <>
      <Text className="PasswordDialog-label">{label}</Text>
      <Input
        disabled={disabled}
        {...rest}
        issues={issueOnBlur && focused[field] ? [] : issues[field]}
        type={type}
        onChange={(update) => {
          const value = parse(update);
          updatePassword({ [field]: value });
        }}
        value={password[field]}
        {...focus(field)}
      />
    </>
  );

  const onClick = async (event) => {
    setState('waiting');
    const { error: saveError } = await onSave({
      ...pick(user, ['userId', 'name']),
      newPassword: password.base,
    });
    if (saveError) {
      const message = getErrorFromBEResponse(saveError);
      setError(message);
    } else {
      onClose(event);
    }
    setState('ready');
  };

  return (
    <form
      onSubmit={(event) => {
        blockEvent(event);
        onClick(event);
      }}
    >
      <Dialog
        closeButtonDisabled={disabled}
        footer={[{ title: 'Save', onClick, state }]}
        height="sm"
        open={open}
        onClose={onClose}
        title="Change Password"
        width="md"
      >
        <LinearLayout
          className="PasswordDialog"
          gap="huge"
          orientation="vertical"
        >
          <Error>{error}</Error>
          <Grid columns={['auto', '1fr']} gap="32px">
            {fields.map(renderInput)}
          </Grid>
        </LinearLayout>
      </Dialog>
    </form>
  );
};

export default PasswordDialog;
