import { useEffect, useMemo, useState } from 'preact/hooks';
import { filter, map, pick } from 'lodash-es';

import blockEvent from '~/helpers/blockEvent';
import chain from '~/helpers/chain';
import cls from '~/helpers/cls';

import routes from '~/routes/routes';
import { push } from '~/actions/route';

import IconButton from '~/components/buttons/IconButton';
import Logo from '~/components/Logo';
import MenuIcon from '~/components/icons/Menu';

import Profile from '~/prebuilt/profile/Profile';
import SplitView from '~/prebuilt/page/SplitView';

import './LayoutDesktop.css';

const Standalone = ({ children }) => children;

const Sidebar = ({ route }) => {
  const routify = ({ path, title }) => {
    const active = route.path.startsWith(path);
    const classes = cls('LayoutDesktop-route', { active });
    return (
      <button className={classes} onClick={() => push(path)} key={path}>
        {title?.toUpperCase()}
        <div className="LayoutDesktop-routeHighlight" />
      </button>
    );
  };

  const renderedRoutes = chain(routes)
    .fn(filter, 'navigate')
    .fn(map, routify).value;

  const [show, setShow] = useState(false);

  const toggleShow = (event) => {
    blockEvent(event);
    setShow(!show);
  };

  useEffect(() => {
    if (show) {
      window.addEventListener('click', toggleShow);
      return () => window.removeEventListener('click', toggleShow);
    }
  }, [show]);

  return (
    <div className="LayoutDesktop-routerWrapper">
      <div className="LayoutDesktop-routeToggle">
        <IconButton color="canvas" onClick={toggleShow}>
          <MenuIcon />
        </IconButton>
      </div>
      <div className={cls('LayoutDesktop-router', { show })}>
        {renderedRoutes}
      </div>
    </div>
  );
};

const dashboardUrl = /** @type {string}*/ (
  import.meta.env.VITE_APP_DASHBOARD_URL
);

const DashboardButton = () => (
  <a
    className="LayoutDesktop-dashboardButton"
    href={dashboardUrl}
    rel="noreferrer"
    target="_blank"
    title="Carriyo Dashboard"
  >
    <IconButton size="lg">
      <Logo size="mini" color="dark" />
    </IconButton>
  </a>
);

const Full = (props) => {
  const { children, route } = props;
  const sidebar = <Sidebar route={route} />;
  return (
    <>
      <SplitView sidebar={sidebar}>
        <div className="LayoutDesktop-page">{children}</div>
      </SplitView>
      <DashboardButton />
      <Profile />
    </>
  );
};

const LayoutDesktop = (props) => {
  const { component: Page, route } = props;
  const { standalone = false } = route;

  const routeProps = pick(route, [
    'routeId',
    'matches',
    'path',
    'url',
    'title',
  ]);

  const Wrapper = useMemo(() => (standalone ? Standalone : Full), [standalone]);

  return (
    <div className="LayoutDesktop">
      <Wrapper route={route}>
        <Page {...routeProps} />
      </Wrapper>
    </div>
  );
};

export default LayoutDesktop;
