import { find, map, orderBy } from 'lodash-es';

// @ts-ignore
import rawCountries from './data/countries.json';

const sortedRawCountries = orderBy(rawCountries, ['name']);

export const countries = map(sortedRawCountries, ({ countryCode, name }) => ({
  label: name,
  value: countryCode,
}));

export const countryCodeFromName = (label) => find(countries, { label })?.value;
export const countryNameFromCode = (value) => find(countries, { value })?.label;

export const countryInfo = map(
  sortedRawCountries,
  ({ countryCode, name, phonePrefix }) => ({
    code: countryCode,
    name,
    phonePrefix: `+${phonePrefix}`,
  })
);

export const countryInfoOrderedByLongestPhonePrefix = orderBy(
  countryInfo,
  [({ phonePrefix }) => phonePrefix.length],
  ['desc']
);

export const statesByCountry = {
  CA: [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland and Labrador', value: 'NL' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Northwest Territories', value: 'NT' },
    { label: 'Nunavut', value: 'NU' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
    { label: 'Yukon', value: 'YT' },
  ],
  US: [
    { label: 'U.S. Armed Forces – Americas', value: 'AA' },
    { label: 'U.S. Armed Forces – Europe', value: 'AE' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Alabama', value: 'AL' },
    { label: 'U.S. Armed Forces – Pacific', value: 'AP' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Micronesia', value: 'FM' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Maine', value: 'ME' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Montana', value: 'MT' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New York', value: 'NY' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Palau', value: 'PW' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'U.S. Minor Outlying Islands', value: 'UM' },
    { label: 'Utah', value: 'UT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'U.S. Virgin Islands', value: 'VI' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Washington', value: 'WA' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wyoming', value: 'WY' },
  ],
};
