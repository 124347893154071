// initial states can be fetched multiple times. Hence always return new object,
// so that mutations to arrays etc, will not affect future fetches.
import { makeBlankAccount } from '~/schema/account';

const blankAccount = makeBlankAccount();

export default () => ({
  // 'global' contains states used across most pages
  global: {
    globalEnumsLoaded: false,
    tenantSettings: {
      enterpriseSsoEnabled: false,
    },
  },
  account: {
    ...blankAccount,
    paymentMethods: undefined,
    subscription: undefined,
  },
  accountSetup: {
    countryOfOperation: 'AE',
  },
  auth: {
    authorizing: true,
    user: {},
    accessByTenantId: {},
    accessToken: '',
    tenantId: '',
    carriyoUserId: '',
  },
  carrier: {
    metadata: {},
  },
  user: {},
  dialogToDisplay: '',
  loading: 0,
  plans: undefined,
  toast: {
    show: false,
    icon: '',
    message: '',
    autoHideDuration: /** @type {number|undefined} */ (undefined),
    progress: /** @type {boolean|undefined} */ (undefined),
  },
  route: {
    blocked: false,
    permissions: {},
  },
});
