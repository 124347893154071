import { useEffect, useState } from 'preact/hooks';

import blockEvent from '~/helpers/blockEvent';
import getErrorFromBEResponse from '~/helpers/getErrorFromBEResponse';
import useAsyncEffect from '~/helpers/useAsyncEffect';

import { useGlobalState } from '~/store';
import { logout } from '~/logout';
import { get as getAccount, getSubscription } from '~/actions/account';
import { getUser, updateUserProfile, upsertPassword } from '~/actions/user';

import LockIcon from '~/components/icons/Lock';
import LogoutIcon from '~/components/icons/Logout';
import SettingsIcon from '~/components/icons/Settings';

import Avatar from '~/components/Avatar';
import Grid from '~/components/layout/Grid';
import IconButton from '~/components/buttons/IconButton';
import LinearLayout from '~/components/layout/LinearLayout';
import Popup from '~/components/layout/Popup';
import Spacer from '~/components/layout/Spacer';
import Spinner from '~/components/icons/Spinner';
import Tag from '~/components/decoration/Tag';
import Text from '~/components/Text';

import EditProfile from './EditProfile';
import Error from '~/prebuilt/generic/Error';
import PasswordDialog from './PasswordDialog';

import './Profile.css';

const OptionButton = (props) => {
  const { Icon, label, onClose, onClick, ...rest } = props;
  return (
    <button
      className="Profile-optionButton"
      onClick={(event) => {
        onClick(event);
        onClose(event);
      }}
      {...rest}
    >
      <Text size="md" weight="bold">
        {label}
      </Text>
      <Icon />
    </button>
  );
};

const ProfilePopup = (props) => {
  const {
    account: { organization },
    auth: { accountId, accessByTenantId = {}, email },
    issue,
    onClose,
    openEditDialog,
    openPasswordDialog,
    subscription: { freeTrial, plan } = {},
    user: { name, picture },
  } = props;

  const { role } = accessByTenantId[accountId] || {};

  const optionButtons = [
    {
      Icon: SettingsIcon,
      label: 'Edit Profile',
      onClick: openEditDialog,
    },
    {
      Icon: LockIcon,
      label: 'Change Password',
      onClick: openPasswordDialog,
    },
    {
      Icon: LogoutIcon,
      label: 'Logout',
      onClick: logout,
    },
  ];

  const planComponent = plan ? (
    <Tag color="canvas">{freeTrial ? 'FREE TRIAL' : plan?.toUpperCase()}</Tag>
  ) : (
    <Spinner size="16px" />
  );

  return (
    <LinearLayout className="Profile-popup" gap="large" orientation="vertical">
      {issue ? (
        <LinearLayout
          className="Profile-popupErrorBox"
          alignItems="center"
          justifyContent="center"
          orientation="vertical"
        >
          <Error size="md">{issue}</Error>
        </LinearLayout>
      ) : (
        <>
          <Grid columns={['1fr', 'auto']}>
            <LinearLayout
              alignItems="flex-start"
              gap="large"
              orientation="vertical"
            >
              <Text className="Profile-name" font="title" weight="bold">
                {name}
              </Text>
              <Text font="title" size="md">
                {email}
              </Text>
              <Text font="title" size="md" style={{ color: '#00ED95' }}>
                {role}
              </Text>
              <Text font="title" size="md">
                {organization}
                &nbsp;
                <Text inline>{`(id: ${accountId?.toUpperCase()})`}</Text>
              </Text>
              {planComponent}
            </LinearLayout>
            <Avatar disabled fade={false} image={picture} title={name} />
          </Grid>
          <Spacer flex />
          <LinearLayout
            gap="medium"
            justifyContent="flex-end"
            orientation="vertical"
          >
            {optionButtons.map(({ Icon, label, onClick }) => (
              <OptionButton
                Icon={Icon}
                key={label}
                label={label}
                onClick={onClick}
                onClose={onClose}
              />
            ))}
          </LinearLayout>
        </>
      )}
    </LinearLayout>
  );
};

const Profile = () => {
  const account = useGlobalState('account');
  const { accountId, subscription } = account;
  const auth = useGlobalState('auth');
  const user = useGlobalState('user');

  const [showEditDialog, setShowEditDialog] = useState(false);
  const toggleEditDialog = () => setShowEditDialog(!showEditDialog);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const togglePasswordDialog = () => setShowPasswordDialog(!showPasswordDialog);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [issue, setIssue] = useState(undefined);

  useAsyncEffect(async () => {
    const { connector: scope, carriyoUserId: userId } = auth;
    if (!userId) return;
    const [
      { error: accountError },
      { error: subscriptionError },
      { error: userError },
    ] = await Promise.all([
      account ? {} : getAccount(),
      subscription ? {} : getSubscription(),
      user.userId ? {} : getUser({ scope, userId }),
    ]);
    const error = accountError || subscriptionError || userError;
    if (error) {
      console.log(error);
      setIssue(getErrorFromBEResponse(error));
    }
  }, [auth, showProfilePopup]);

  const onClick = () => setShowProfilePopup(true);
  const onClose = () => setShowProfilePopup(false);

  useEffect(() => {
    window.addEventListener('click', onClose);
    return () => window.removeEventListener('click', onClose);
  }, []);

  if (!issue && !accountId) return null;

  const content = (
    <ProfilePopup
      account={account}
      auth={auth}
      issue={issue}
      onClose={onClose}
      openEditDialog={toggleEditDialog}
      openPasswordDialog={togglePasswordDialog}
      subscription={subscription}
      user={user}
    />
  );

  const { name, picture, userId } = user;
  const loadingUser = !userId;

  return (
    <>
      <div className="Profile">
        <Popup
          className="Profile-popupWrapper"
          content={content}
          onClick={blockEvent}
          position={null}
          show={showProfilePopup}
        >
          <IconButton
            disabled={loadingUser || showProfilePopup}
            onClick={onClick}
            size="lg"
          >
            <Avatar
              disabled
              fade={false}
              image={picture}
              title={name}
              size="sm"
              waiting={loadingUser}
            />
          </IconButton>
        </Popup>
      </div>
      <EditProfile
        open={showEditDialog}
        onClose={toggleEditDialog}
        onSave={updateUserProfile}
        user={user}
      />
      <PasswordDialog
        open={showPasswordDialog}
        onClose={togglePasswordDialog}
        onSave={upsertPassword}
        user={user}
      />
    </>
  );
};

export default Profile;
