import { chunk } from 'lodash-es';

import cls from '~/helpers/cls';

import Text from '~/components/Text';

import './Error.css';

const Error = ({ children: rawChildren, ...props }) => {
  const classes = cls('Error', { active: Boolean(rawChildren) });
  const children =
    typeof rawChildren === 'string'
      ? chunk(rawChildren, 80).map((text) => <div key={text}>{text}</div>)
      : rawChildren;
  return (
    <Text className={classes} color="error" weight="bolder" {...props}>
      {children}
    </Text>
  );
};

export default Error;
