import delay from 'delay';
import * as apiClient from '../api-client';
import { reader, writer } from '../helpers/action';
import { updateUser, getStates } from '../store';

export const setState = updateUser;

export const verifyAndUpsertUser = writer(
  'user',
  async ({ email, fromInviteFlow = false, ...payload }, store = true) => {
    const { password, token } = payload;
    const [{ default: auth0Client }, { data: user }] = await Promise.all([
      import('../auth0client'),
      apiClient.verifyAndUpsertUser(payload),
    ]);
    if (fromInviteFlow) {
      return user;
    }
    await delay(1000);
    auth0Client.login({
      realm: 'Username-Password-Authentication',
      username: email,
      password,
      redirectUri: `${window.location.origin}/get-started?${
        token ? `?token=${token}` : ''
      }`,
    });
    if (store) setState(user);
    return user;
  },
  { operation: 'create' }
);

export const verifyAndChangePassword = writer(
  'change-password',
  async (payload) => {
    const { data: user } = await apiClient.verifyAndChangePassword(payload);
    return user;
  },
  { operation: 'create' }
);

export const sendVerificationRequest = reader(
  'verification email',
  apiClient.sendVerificationRequest,
  {
    operation: 'send',
    onFailure: '',
  }
);

export const getUser = reader(
  'user',
  async ({ scope, userId }, store = true) => {
    const data = await apiClient.getUser({ scope, userId });
    if (store) setState(data);
    return data;
  },
  { operation: 'get' }
);

export const createUser = writer(
  'user',
  async ({ email, verify, ...payload }, store = true) => {
    const { password, token } = payload;
    const [{ default: auth0Client }, { data: user }] = await Promise.all([
      import('../auth0client'),
      apiClient.createUser(payload),
    ]);
    await delay(1000);
    auth0Client.login({
      realm: 'Username-Password-Authentication',
      username: email,
      password,
      redirectUri: `${window.location.origin}/get-started${
        token ? `?verify=${verify}&stage=account-setup` : ''
      }`,
    });
    if (store) setState(user);
    return user;
  },
  { operation: 'create' }
);

export const updateUserProfile = writer(
  'user',
  async (payload, store = true) => {
    await apiClient.updateUserProfile(payload);
    if (store) {
      const { account } = getStates();
      updateUser({ ...account, ...payload });
    }
    return payload;
  }
);

export const upsertPassword = writer(
  'password',
  async (password) => {
    const { data } = await apiClient.upsertPassword(password);
    return data;
  },
  { operation: 'change' }
);

export const uploadPicture = writer(
  'picture',
  async (param) => {
    const { data } = await apiClient.uploadUserProfilePicture(param);
    return data;
  },
  {
    onSuccess: null,
    operation: 'parse',
  }
);
