import chain from '~/helpers/chain';

export const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const param = {};
  for (const key of urlSearchParams.keys()) {
    param[key] = urlSearchParams.get(key);
  }
  return param;
};

export const encodeParam = (param) =>
  chain(param).fn(JSON.stringify).fn(btoa).fn(encodeURIComponent).value;

export const decodeParam = (param) =>
  chain(param).fn(decodeURIComponent).fn(atob).fn(JSON.parse).value;

export const setUrlParams = (params, action = 'replace') => {
  const { origin, pathname } = location;
  const url = new URL(`${origin}${pathname}`);
  Object.entries(params).forEach(([param, value]) =>
    url.searchParams.append(param, value)
  );
  window.history[`${action}State`]({}, null, url.toString());
};

export const removeUrlParam = (param) => {
  const params = getUrlParams();
  const { [param]: value, ...update } = params;
  setUrlParams(update);
};
