import cls from '~/helpers/cls';
import './Field.css';

/**
 * @param {object} props
 * @param {string} [props.className]
 * @param {'sharp'|'round'} [props.corner = 'round']
 * @param {boolean} [props.disabled = false]
 * @param {import('preact').JSX.FocusEventHandler<HTMLInputElement>} [props.onBlur]
 * @param {import('preact').JSX.GenericEventHandler<HTMLInputElement>} [props.onChange]
 * @param {import('preact').JSX.FocusEventHandler<HTMLInputElement>} [props.onFocus]
 * @param {string} [props.placeholder]
 * @param {boolean} [props.required = false]
 * @param {string | import('preact').JSX.CSSProperties} [props.style]
 * @param {string} [props.type]
 * @param {string} [props.value = '']
 * @param {string} [props.id = '']
 */
const Field = (props) => {
  const {
    className = undefined,
    corner = 'round',
    disabled = false,
    id = undefined,
    loading = false,
    maxLength = undefined,
    onBlur = undefined,
    onChange = undefined,
    onFocus = undefined,
    padding = true,
    placeholder = undefined,
    required = false,
    style = undefined,
    type = undefined,
    value = '',
  } = props;

  const classes = cls('Field', { corner, padding }, className);

  return (
    <input
      className={classes}
      disabled={disabled || loading}
      id={id}
      maxLength={maxLength}
      onBlur={onBlur}
      onFocus={onFocus}
      onInput={onChange}
      placeholder={loading ? 'Loading...' : placeholder}
      required={required}
      style={style}
      type={type}
      value={value}
    />
  );
};

export default Field;
