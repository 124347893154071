import { get, set } from 'lodash-es';

export const blank = () => {
  const issue = {};
  const adder = (field) => {
    const list = get(issue, field, []);
    if (!get(issue, field)) set(issue, field, list);
    return Array.prototype.push.bind(list);
  };
  return [issue, adder];
};

export const has = (issue) => Object.values(issue).some((val) => val.length);
