import cls from '~/helpers/cls';
import './CheckCircle.css';

import CheckCircleIcon from '~/components/icons/CheckCircleIcon';
import RemoveCircleIcon from '~/components/icons/RemoveCircleIcon';
import CheckCircleOutlinedIcon from '~/components/icons/CheckCircleOutlinedIcon';

const component = 'CheckCircle';

const Icon = (checked, indeterminate) => {
  if (checked) {
    if (indeterminate) return RemoveCircleIcon;
    return CheckCircleIcon;
  }
  return CheckCircleOutlinedIcon;
};

const CheckCircle = (props) => {
  const {
    checked = false,
    className = undefined,
    disabled = false,
    onlyFade = false,
    id = undefined,
    indeterminate = false,
    onChange = undefined,
    style = undefined,
  } = props;
  const classes = cls(component, { onlyFade }, className);
  const SVG = Icon(checked, indeterminate);
  return (
    <div className="CheckCircle">
      <input
        checked={checked}
        className={classes}
        disabled={disabled}
        id={id}
        onChange={onChange}
        style={style}
        type="checkbox"
      />
      <SVG />
    </div>
  );
};

export default CheckCircle;
