import cls from '~/helpers/cls';
import './Popup.css';

const Popup = (props) => {
  const {
    children,
    className = undefined,
    content,
    padding = true,
    position = 'bottom',
    show = false,
    style = undefined,
    ...rest
  } = props;

  const classes = cls('Popup-content', { padding, position, show }, className);

  return (
    <div className="Popup" {...rest}>
      <div className="Popup-children">{children}</div>
      {content && (
        <div className={classes} style={style} tabIndex={-1}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Popup;
