export default (pattern) => {
  const words = pattern
    .trim()
    .replace(/\s+/g, ' ')
    .toLowerCase()
    .split(' ')
    .filter(Boolean);
  return words.length
    ? (text) => {
        const sample = text.toLowerCase();
        return words.some((word) => sample.includes(word));
      }
    : () => true;
};
