import camelCaseKeys from '~/helpers/camelCaseKeys';
import snakeCaseKeys from '~/helpers/snakeCaseKeys';
import { nodeMiddlewareClient, nodeMiddlewareIOClient } from './base';

export const checkVerificationToken = async (token) => {
  const { data } = await nodeMiddlewareClient.get(
    `/public/email-verification/check-token/${token}`
  );
  return data;
};

export const verifyAndUpsertUser = async (params) => {
  const { data } = await nodeMiddlewareClient.post(
    '/public/email-verification/upsert-user',
    params
  );
  return data;
};

export const verifyAndChangePassword = async (params) => {
  const { data } = await nodeMiddlewareClient.post(
    '/public/email-verification/change-password',
    params
  );
  return data;
};

export const sendVerificationRequest = async (params) => {
  const { data } = await nodeMiddlewareClient.post(
    '/public/email-verification/request',
    params
  );
  return data;
};

export const getUser = async (params) => {
  const {
    data: { role = 'unknown', ...data },
  } = await nodeMiddlewareClient.post('/users/get', {
    ...snakeCaseKeys(params),
  });
  return camelCaseKeys({ role, ...data });
};

export const updateUserProfile = async (params) =>
  nodeMiddlewareClient.post('/users/profile', snakeCaseKeys(params));

export const createUser = async (payload) =>
  nodeMiddlewareClient.post('public/email-verification/user/create', payload);

export const upsertPassword = async (param) =>
  nodeMiddlewareClient.post('/users/password/change', snakeCaseKeys(param));

export const uploadUserProfilePicture = async ({ data, name, scope }) =>
  nodeMiddlewareIOClient.post(
    `/upload/profile-image?fileNameToBeSet=${name}&scope=${scope}`,
    data,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

export const deleteUserProfilePicture = async (data) =>
  nodeMiddlewareClient.delete('/delete/profile-image', { data });
