import { createStore } from './state-manager';
import getInitialState from './getInitialState';

export const { useGlobalState, getStates, updateStates, createPropUpdater } =
  createStore(getInitialState());

export const {
  updateGlobal,
  updateAccount,
  updateAuth,
  updateDialogToDisplay,
  updateLoading,
  updatePlans,
  updateRoute,
  updateToast,
  updateAccountSetup,
  updateUser,
  updateCarrier,
} = {
  updateGlobal: createPropUpdater('global'),
  updateAccount: createPropUpdater('account'),
  updateAuth: createPropUpdater('auth'),
  updateDialogToDisplay: createPropUpdater('dialogToDisplay'),
  updateLoading: createPropUpdater('loading'),
  updatePlans: createPropUpdater('plans'),
  updateRoute: createPropUpdater('route'),
  updateToast: createPropUpdater('toast'),
  updateAccountSetup: createPropUpdater('accountSetup'),
  updateUser: createPropUpdater('user'),
  updateCarrier: createPropUpdater('carrier'),
};

// for debugging purpose
Object.defineProperty(window, 'globalStates', {
  get: getStates,
  set() {},
});
