import CheckCircle from '~/components/inputs/CheckCircle';
import PasswordField from '~/components/fields/PasswordField';
import PhoneField from '~/components/phone-number/PhoneField';
import Select from '~/components/inputs/Select';
import TextField from '~/components/fields/TextField';

const inputMap = {
  select: Select,
  'check-circle': CheckCircle,
  password: PasswordField,
  'phone-number': PhoneField,
  text: TextField,
};

const Input = (props) => {
  const { type, ...rest } = props;
  const Component = inputMap[type] || inputMap.text;
  return <Component {...rest} />;
};

export default Input;
