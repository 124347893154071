import { useEffect, useState } from 'preact/hooks';
import { identity } from 'lodash-es';

import blockEvent from '~/helpers/blockEvent';
import getErrorFromBEResponse from '~/helpers/getErrorFromBEResponse';
import * as issue from '~/helpers/issue';
import useObjectState from '~/helpers/useObjectState';

import { uploadPicture } from '~/actions/user';

import Avatar from '~/components/Avatar';
import Dialog from '~/components/layout/Dialog';
import Grid from '~/components/layout/Grid';
import InputLabel from '~/components/inputs/InputLabel';
import LinearLayout from '~/components/layout/LinearLayout';
import Text from '~/components/Text';

import Error from '~/prebuilt/generic/Error';
import Input from '~/prebuilt/generic/Input';

import { decomposeNumber } from '~/components/phone-number/PhoneField';

import './EditProfile.css';

const getFileExtension = (text = '') => text.split('.').pop();

const fields = [
  {
    field: 'name',
    label: 'Name',
    parse: ({ target: { value } }) =>
      value.replace(/\s+/g, ' ').replace(/[^a-zA-Z0-9\s'-]/g, ''),
    required: true,
  },
  {
    field: 'userId',
    label: 'Email',
    disabled: true,
    required: true,
  },
  {
    field: 'phoneNumber',
    label: 'Phone Number',
    parse: identity,
    type: 'phone-number',
  },
];

const parseText = ({ target }) => target.value;

const sanitizeUser = ({
  name = '',
  phoneNumber = '',
  picture,
  marketingSubscribed = false,
  role,
  scope,
  userId,
}) => ({
  name: name.trim(),
  phoneNumber: decomposeNumber(phoneNumber).phoneNumber.trim()
    ? phoneNumber.trim()
    : '',
  marketingSubscribed,
  picture: picture && /gravatar/.test(picture) ? null : picture,
  role,
  scope,
  userId,
});

const getIssues = (user) => {
  const { name } = user;
  const [issues, adder] = issue.blank();
  const nameIssue = adder('name');
  if (name) {
    if (name.length < 2) nameIssue('Minimum 2 characters');
  } else {
    nameIssue('Name is required');
  }
  return issues;
};

const EditProfile = (props) => {
  const { open, onClose, onSave, user } = props;

  const [error, setError] = useState(undefined);
  const [state, setState] = useState('ready');
  const [focused, setFocused] = useState({});
  const [editingUser, setEditingUser, updateEditingUser] = useObjectState({});

  const issues = getIssues(sanitizeUser(editingUser));

  const focus = (field) => ({
    onBlur: () => setFocused({ ...focused, [field]: false }),
    onFocus: () => setFocused({ ...focused, [field]: true }),
  });

  useEffect(() => {
    setError(undefined);
    setState(issue.has(issues) ? 'disabled' : 'ready');
  }, [editingUser]);

  useEffect(() => {
    setEditingUser(sanitizeUser(user));
  }, [open, user]);

  const disabled = state === 'waiting';

  const renderInput = ({
    field,
    label,
    parse = parseText,
    type = 'text',
    ...rest
  }) => (
    <>
      <Text className="EditProfile-label">{label}</Text>
      <Input
        disabled={disabled}
        {...rest}
        issues={focused[field] ? [] : issues[field]}
        type={type}
        onChange={(update) => {
          const value = parse(update);
          updateEditingUser({ [field]: value });
        }}
        value={editingUser[field]}
        {...focus(field)}
      />
    </>
  );

  const { marketingSubscribed } = editingUser;

  const onClick = async (event) => {
    setState('waiting');
    const payload = sanitizeUser(editingUser);
    const { error: saveError } = await onSave(payload);
    if (saveError) {
      const message = getErrorFromBEResponse(saveError);
      setError(message);
    } else {
      onClose(event);
    }
    setState('ready');
  };

  const onAvatarChange = async ({ target }) => {
    const [file] = target.files;
    const packet = new FormData();
    packet.append('files', file);
    packet.append(
      'fileInfo',
      "{ alternativeText: '', caption: '', name: null }"
    );
    const { data: image, error: uploadError } = await uploadPicture({
      data: packet,
      name: `${user.userId}.${getFileExtension(file.name) || 'bin'}`,
      scope: user.scope,
    });
    if (uploadError) {
      const message = getErrorFromBEResponse(uploadError);
      setError(message);
      return { error: uploadError };
    }
    const { url } = image;
    updateEditingUser({ picture: url });
    return {};
  };

  return (
    <form
      onSubmit={(event) => {
        blockEvent(event);
        onClick(event);
      }}
    >
      <Dialog
        closeButtonDisabled={disabled}
        footer={[{ title: 'Save', state }]}
        open={open}
        onClose={onClose}
        title="Edit Profile"
        width="lg"
      >
        <LinearLayout className="EditProfile" gap="huge" orientation="vertical">
          <Error>{error}</Error>
          <Grid columns={['380px', '1fr']} gap="32px">
            <Grid columns={['auto', '1fr']} gap="16px">
              {fields.map(renderInput)}
            </Grid>
            <LinearLayout alignItems="center" orientation="vertical">
              <Avatar
                disabled={disabled}
                image={editingUser.picture}
                onChange={onAvatarChange}
                onClear={() => updateEditingUser({ picture: null })}
                size="lg"
                title={user.name}
              />
            </LinearLayout>
          </Grid>
          <InputLabel
            checked={marketingSubscribed}
            disabled={disabled}
            Input={Input}
            label={<b>Receive news and promotional content from Carriyo</b>}
            onChange={({ target }) =>
              updateEditingUser({ marketingSubscribed: target.checked })
            }
            variant="left"
            type="check-circle"
          />
        </LinearLayout>
      </Dialog>
    </form>
  );
};

export default EditProfile;
