// this camelCase is not same as lodash.camelCase.
// e.g what3words would be converted to what3Words by lodash
// whereas what3words would remain what3words with this one.

const camelCase = (key) =>
  key
    .trim()
    .replace(/^./, (match) => match.toLowerCase())
    .replace(/[_ ]+(.)/g, (match, group) => group.toUpperCase());

/**
 * @param {GenericObject} obj
 * @return {GenericObject}
 * @template GenericObject
 */
function camelCaseKeys(obj) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    // @ts-ignore
    return obj.map(camelCaseKeys);
  }
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      camelCase(key),
      camelCaseKeys(value),
    ])
  );
}

export default camelCaseKeys;
