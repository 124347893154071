import { useState } from 'preact/hooks';
import { merge, isEqual } from 'lodash-es';

function useObjectState(defaultVal = {}, force = false) {
  const [state, setState] = useState(defaultVal);
  let interimState = state;
  // overcome stale state being used, if state is updated within single event tick
  const interceptedSetState = (newState) => {
    if (force || !isEqual(newState, state)) {
      interimState = newState;
      setState(interimState);
      return true;
    }
    return false;
  };
  const updateState = (newState) =>
    interceptedSetState({ ...interimState, ...newState });
  const deepMergeState = (newState) =>
    interceptedSetState(merge({}, interimState, newState));
  return [state, interceptedSetState, updateState, deepMergeState];
}

export default useObjectState;
