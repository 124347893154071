import cls from '../../helpers/cls';
import './Spacer.css';

/**
 * @param {object} p
 * @param {boolean} [p.flex]
 * @param {string} [p.x]
 * @param {string} [p.y]
 */
function Spacer({ flex, x, y }) {
  const classes = cls('Spacer', { flex, x, y });
  return <div className={classes} />;
}

export default Spacer;
