import { Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { map } from 'lodash-es';

import cls from '../../helpers/cls';

import Text from '~/components/Text';
import './InputLabel.css';

const Issues = (props) => {
  const { issues } = props;
  const { length } = issues;

  const content = (
    <ul>
      {map(issues, (issue) => (
        <li>{issue}</li>
      ))}
    </ul>
  );

  const className = 'InputLabel-issue';

  return (
    <div
      className={className}
      style={{ height: length * 16 + (length > 0 ? 16 : 1) }}
    >
      {content}
    </div>
  );
};

const InputLabel = (props) => {
  const {
    corner: rawCorner = 'round',
    disabled = false,
    htmlFor = undefined,
    issues = [],
    Input = Fragment,
    inputProps = {},
    label = null,
    labelProps = {},
    onBlur: externalOnBlur = () => {},
    onFocus: externalOnFocus = () => {},
    outline = false,
    required = undefined,
    style = undefined,
    width = 'auto',
    variant = undefined,
    ...rest
  } = props;

  const [focus, setFocus] = useState(false);

  const onBlur = (event) => {
    setFocus(false);
    externalOnBlur(event);
  };

  const onFocus = (event) => {
    setFocus(true);
    externalOnFocus(event);
  };

  const error = issues.length > 0;

  const corner = variant === 'outlined' ? 'round' : rawCorner;

  const inputPartial = (
    <div className="InputLabel-input">
      <Input
        {...rest}
        corner={corner}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        required={required}
        {...inputProps}
      />
    </div>
  );

  const textPartial = label ? (
    <Text
      className="InputLabel-text"
      disabled={disabled}
      nobreak
      {...labelProps}
    >
      {label}
      {required && ' *'}
    </Text>
  ) : null;

  const classes = cls('InputLabel', {
    corner,
    disabled,
    error: Boolean(error),
    focus,
    outline,
    variant,
    width,
  });

  return (
    <label className={classes} htmlFor={htmlFor} style={style}>
      <div className="InputLabel-container">
        {textPartial}
        {inputPartial}
      </div>
      <Issues issues={issues} />
    </label>
  );
};

export default InputLabel;
