import { identity } from 'lodash-es';
import { countryInfoOrderedByLongestPhonePrefix } from '../../enums';

import cls from '~/helpers/cls';
import useObjectState from '~/helpers/useObjectState';

import CountryWithPhone from './CountryWithPhone';
import Field from '../inputs/Field';
import InputLabel from '~/components/inputs/InputLabel';
import Spacer from '../layout/Spacer';
import Text from '../Text';

import './PhoneField.css';

export const decomposeNumber = (number) => {
  const { code: countryCode = 'AE', phonePrefix = '+971' } =
    countryInfoOrderedByLongestPhonePrefix.find(({ phonePrefix: prefix }) =>
      number.startsWith(prefix)
    ) || {};
  const phoneNumber = number.replace(phonePrefix, '');
  return {
    countryCode,
    phonePrefix,
    phoneNumber,
    completeNumber: `${phonePrefix}${phoneNumber}`,
  };
};

const composeNumber = (prefix = '', number = '') => `${prefix}${number}`;

const PhoneField = (props) => {
  const {
    corner,
    className = '',
    disabled = false,
    onChange,
    onBlur = identity,
    onFocus = identity,
    required = false,
    value = '',
    ...restProps
  } = props;

  const {
    countryCode = '',
    phonePrefix = '',
    phoneNumber = '',
  } = decomposeNumber(value);

  const [focused, , updateFocused] = useObjectState({
    popup: false,
    field: false,
  });

  const focusSomeExcept = (except) => {
    const { [except]: unused, ...rest } = focused;
    return Object.values(rest).some(Boolean);
  };

  const focusProps = (field) => ({
    onFocus: (event) => {
      updateFocused({ [field]: true });
      onFocus(event);
    },
    onBlur: (event) => {
      updateFocused({ [field]: false });
      if (!focusSomeExcept(field)) onBlur(event);
    },
  });

  const classes = cls('PhoneField', { disabled, corner }, className);

  return (
    <div className={classes}>
      <CountryWithPhone
        countryCode={countryCode}
        setCountryCode={(prefix) =>
          onChange(composeNumber(prefix, phoneNumber))
        }
        disabled={disabled}
        {...focusProps('popup')}
      />
      <Text className={cls('PhoneField-prefix', { disabled })}>
        {phonePrefix}
      </Text>
      <Spacer x={3} />
      <Field
        id="phoneNumber"
        className="PhoneField-field"
        corner={corner}
        disabled={disabled}
        maxLength="25"
        placeholder="23 674 0472"
        required={required}
        onChange={(event) => {
          const { value } = event.target;
          const sanitized = value
            .replace(/[^0-9\s-]/g, '')
            .replace(/-+/g, '-')
            .replace(/\s-/g, ' ')
            .replace(/\s+/g, ' ');
          event.target.value = sanitized;
          onChange(composeNumber(phonePrefix, sanitized));
        }}
        type="text"
        value={phoneNumber}
        {...restProps}
        {...focusProps('field')}
      />
    </div>
  );
};

const PhoneFieldWrapper = (props) => (
  <InputLabel {...props} Input={PhoneField} outline width="full" />
);

export default PhoneFieldWrapper;
