import { map } from 'lodash-es';
import cls from '~/helpers/cls';
import Popup from '~/components/layout/Popup';
import './Menu.css';

const Menu = (props) => {
  const {
    children,
    className = undefined,
    length = 'medium',
    options = [],
    position = undefined,
    render = undefined,
    show = false,
    style = undefined,
  } = props;

  const classes = cls('Menu', { length }, className);

  const content = map(options, render);

  return (
    <Popup
      className={classes}
      content={content}
      padding={false}
      position={position}
      show={show}
      style={style}
    >
      {children}
    </Popup>
  );
};

export default Menu;
