import Field from '~/components/inputs/Field';
import InputLabel from '~/components/inputs/InputLabel';

const TextField = (props) => <Field {...props} type="text" />;

/*
 * @param {Parameters<Field>} props
 */
const Wrapper = (props) => (
  <InputLabel width="full" {...props} Input={TextField} outline />
);

export default Wrapper;
