/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useRef } from 'preact/hooks';
import { map } from 'lodash-es';
import cls from '~/helpers/cls';
import './Dialog.css';

import CloseIcon from '~/components/icons/CloseIcon';
import FormButton from '~/components/buttons/FormButton';
import IconButton from '~/components/buttons/IconButton';
import Spacer from '~/components/layout/Spacer';
import Text from '~/components/Text';

const HeaderButton = (props) => {
  const { color, component, disabled, Icon, onClick, className } = props;

  if (component) return component;

  return (
    <IconButton
      color={color}
      disabled={disabled}
      onClick={onClick}
      className={className}
      size="lg"
    >
      <Icon fontSize="large" />
    </IconButton>
  );
};

const FooterButton = (props) => {
  const {
    color = 'primary',
    component = undefined,
    onClick = undefined,
    state = 'ready',
    title = undefined,
    width = 'short',
  } = props;

  if (component) return component;

  return (
    <FormButton
      buttonVariant="standard"
      color={color}
      onClick={onClick}
      state={state}
      textProps={{ size: 'medium' }}
      variant="action"
      width={width}
    >
      {title}
    </FormButton>
  );
};

const Dialog = (props) => {
  const {
    children = undefined,
    className = undefined,
    closeButtonDisabled = false,
    closeButtonShown = true,
    closeOnBlur = false,
    footer = undefined,
    header = [],
    height = 'md',
    onClose = undefined,
    open = false,
    style = undefined,
    title = undefined,
    width = 'md',
  } = props;

  const dialogRef = useRef(null);

  return (
    <dialog
      className={cls('Dialog', { height, width })}
      ref={dialogRef}
      onClick={(event) => {
        const shouldClose =
          event.target === dialogRef.current && closeOnBlur && onClose;
        if (shouldClose) onClose(event);
      }}
      open={open}
    >
      <div className={cls('Dialog-body', { height, width })} style={style}>
        <div className="Dialog-header">
          <Text variant="h0" weight="boldest">
            {title}
          </Text>
          <Spacer flex />
          {map(header, ({ key, ...props }) => (
            <HeaderButton {...props} key={key} />
          ))}
          {closeButtonShown && (
            <HeaderButton
              disabled={closeButtonDisabled}
              Icon={CloseIcon}
              onClick={onClose}
            />
          )}
        </div>
        <div className={cls('Dialog-content', [], className)}>{children}</div>
        {footer && (
          <div className="Dialog-footer">
            {map(footer, (props) => (
              <FooterButton {...props} key={props.title} />
            ))}
          </div>
        )}
      </div>
    </dialog>
  );
};

export default Dialog;
