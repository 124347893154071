import countryFlag from './country-flags';
import cls from '~/helpers/cls';
import './CountryFlag.css';

const CountryFlag = (props) => {
  const { country, className, disabled = false, size = 'medium' } = props;
  const classes = cls('CountryFlag', { disabled, size }, className);
  return <div className={classes}>{countryFlag[country]}</div>;
};

export default CountryFlag;
