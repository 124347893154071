// @ts-nocheck
import { useEffect, useState, useRef } from 'preact/hooks';
import { isEqual } from 'lodash-es';

/**
 * @template Store
 * @param {Store} initialState
 * @returns {{
 *  getStates(): Store;
 *  updateStates(partial: Partial<Store>): void;
 *  createPropUpdater<Prop extends keyof Store>(propName: Prop): (partial: Partial<Store[Prop]>) => void;
 *  useGlobalState<Prop extends keyof Store>(prop: Prop): Store[Prop],
 * }}
 */
function createStore(initialState = {}) {
  // "The" global store
  let store = initialState;

  // internal publisher-subscriber system to
  // notify containers of store changes.
  const pubsub = {
    handlers: [],
    subscribe(handler) {
      // console.log('subscribed');
      if (!this.handlers.includes(handler)) {
        this.handlers.push(handler);
      }
    },
    unsubscribe(handler) {
      // console.log('unsubscribed');
      const index = this.handlers.indexOf(handler);
      if (index > -1) {
        this.handlers.splice(index, 1);
      }
    },
    notify(newStore) {
      this.handlers.forEach((handler) => handler(newStore));
    },
  };

  const getStates = () => ({ ...store });

  const updateStates = (partial) => {
    const newStore = {
      ...store,
      ...partial,
    };
    store = newStore;
    pubsub.notify(newStore);
  };

  const useGlobalState = (propName) => {
    // prevent stale closure in case of multiple updates
    const ref = useRef(store[propName]);
    // but also have a state, so that UI refresh can be triggered
    const [, setState] = useState(store[propName]);

    useEffect(() => {
      const newStateHandler = (newStore) => {
        const newState = newStore[propName];
        // console.log('current state', state);
        // console.log('new state', newState);
        // console.log('isEqual', isEqual(ref.current, newState));
        if (!isEqual(ref.current, newState)) {
          ref.current = newState;
          setState(newState);
        }
      };
      pubsub.subscribe(newStateHandler);
      // on component unmount, unsubscribe to prevent mem leak
      return () => pubsub.unsubscribe(newStateHandler);
    }, [propName]);

    return ref.current;
  };

  const createPropUpdater = (propName) => (partial) =>
    updateStates({ [propName]: partial });

  return { useGlobalState, getStates, updateStates, createPropUpdater };
}

export { createStore };
