import cls from '../../helpers/cls';
import './LinearLayout.css';

const component = 'LinearLayout';

/*
 * @param {'small' | 'medium' | 'large' | undefined} [props.gap=undefined]
 */

const LinearLayout = (props) => {
  const {
    orientation = 'horizontal',
    alignItems = orientation === 'horizontal' ? 'center' : undefined,
    children = undefined,
    className = undefined,
    gap = undefined,
    justifyContent = undefined,
    style = undefined,
  } = props;

  const classes = cls(
    component,
    {
      alignItems,
      gap,
      justifyContent,
      orientation,
    },
    className
  );

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export default LinearLayout;

export const Centric = (props) => (
  <LinearLayout alignItems="center" justifyContent="center" {...props} />
);

export const Horizontal = (props) => (
  <LinearLayout orientation="horizontal" {...props} />
);

export const Vertical = (props) => (
  <LinearLayout orientation="vertical" {...props} />
);
