/* eslint-disable no-useless-escape */
import './logger.base';
import { log } from './api-client/base';

window.$logger.init({
  url: '', // not needed
  // @ts-ignore
  logLevels: import.meta.env.VITE_APP_ENABLE_LOGGING
    ? ['error', 'warn', 'info']
    : [],
  dispatchPayload(url, payload) {
    return log(payload);
  },
});
