import cls from '~/helpers/cls';
import './SplitView.css';

import Logo from '~/components/Logo';

const SplitView = ({ children, className, sidebar, variant = 'page' }) => {
  const classes = cls('SplitView', { variant });
  const pageClasses = cls('SplitView-content', {}, className);
  return (
    <div className={classes}>
      <div className="SplitView-sidebar">
        <div className="SplitView-sidebarContent">
          <a href="/account">
            <Logo color="light" />
          </a>
          {sidebar}
        </div>
      </div>
      <div className="SplitView-mainbar">
        <div className={pageClasses}>{children}</div>
      </div>
      <div className="SplitView-logo">
        <Logo color="dark" />
      </div>
    </div>
  );
};

export default SplitView;
