import { push, replace, unblock } from '../actions/route';

const routingType = {
  push,
  replace,
};

function route(path = '', type = 'push') {
  unblock();
  return routingType[type](path);
}

export default route;
