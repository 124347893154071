import { useMemo, useState } from 'preact/hooks';
import cls from '~/helpers/cls';

import FileInput from '~/components/inputs/FileInput';
import CloseIcon from '~/components/icons/CloseIcon';
import IconButton from '~/components/buttons/IconButton';
import Spinner from '~/components/icons/Spinner';
import Text from '~/components/Text';
import './Avatar.css';

const Image = ({ image, title }) => <img alt="" src={image} title={title} />;

const textSizeMap = {
  sm: 'lg',
  md: 'lg',
  lg: 'xxxl',
};

const textSize = (size) => textSizeMap[size] || textSizeMap.md;

const spinnerSizeMap = {
  sm: '15px',
  md: '31px',
  lg: '47px',
};

const spinnerSize = (size) => spinnerSizeMap[size] || spinnerSizeMap.md;

const Title = ({ size, title }) => {
  if (!title) return null;
  const text = title
    .split(' ')
    .slice(0, 2)
    .map(([first]) => first?.toUpperCase())
    .join('');
  return (
    <Text color="canvas" size={textSize(size)}>
      {text}
    </Text>
  );
};

const colors = ['disabled', 'primary', 'secondary', 'tertiary'];

const { length: colorCount } = colors;

const colorOf = (text = '') => {
  const hash = new TextEncoder()
    .encode(text)
    .reduce((sum, byte) => sum + byte, 0);
  return colors[hash % colorCount];
};

const accept = ['.png', '.jpg', '.jpeg'];

const Avatar = (props) => {
  const {
    className: classes,
    disabled,
    fade = disabled,
    onChange: externalOnChange,
    onClear,
    image,
    size = 'md',
    title,
    waiting: externalWaiting,
    ...rest
  } = props;

  const color = image ? 'canvas' : colorOf(title);
  const className = cls('Avatar', { color, disabled, fade, size }, classes);
  const [waiting, setWaiting] = useState(false);

  const Component = useMemo(() => (image ? Image : Title), [image]);

  const onChange = async (event) => {
    setWaiting(true);
    const result = (await externalOnChange(event)) || {};
    setWaiting(false);
    return result;
  };

  return (
    <div className="Avatar-wrapper">
      {waiting || externalWaiting ? (
        <Text className={className} color="secondary">
          <Spinner size={spinnerSize(size)} />
        </Text>
      ) : (
        <>
          <FileInput
            accept={accept}
            className={className}
            disabled={disabled}
            onChange={onChange}
            {...rest}
          >
            <Component image={image} title={title} size={size} />
          </FileInput>
          {!disabled && onClear && image && (
            <IconButton
              className="Avatar-clear"
              color="error"
              onClick={onClear}
              size="vs"
            >
              <CloseIcon fontSize="tiny" />
            </IconButton>
          )}
        </>
      )}
    </div>
  );
};

export default Avatar;
