import cls from '../../helpers/cls';
import './Grid.css';

const component = 'Grid';

const Grid = (props) => {
  const {
    children = undefined,
    className = undefined,
    gap = undefined,
    columnGap = gap,
    columns = ['1fr'],
    rowGap = gap,
    rows = ['1fr'],
    ...rest
  } = props;

  const classes = cls(component, [], className);

  const style = {
    ...rest,
    gridColumnGap: columnGap,
    gridRowGap: rowGap,
    gridTemplateColumns: columns.join(' '),
    gridTemplateRows: rows.join(' '),
  };

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export default Grid;
