function promisify(callbackFunction) {
  return (...args) =>
    new Promise((resolve, reject) => {
      callbackFunction(...args, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
}

export default promisify;
