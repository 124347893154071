import { capitalize } from 'lodash';
import forceRedirect from '~/helpers/forceRedirect';
import getErrorFromBEResponse from '~/helpers/getErrorFromBEResponse';
import pastTense from '~/helpers/pastTense';
import * as toastActions from '~/actions/toast';

/**
 * @template T
 * @param {string} entity
 * @param {(...any) => Promise<T>} func
 * @param {object} options
 * @param {string} [options.operation='load']
 * @param {((error: string) => any)|string} [options.onFailure='Unable to load <entity>']
 */
export const reader =
  (entity, func, options = {}) =>
  async (...args) => {
    const {
      operation = 'load',
      onFailure = `Unable to ${operation} ${entity}`,
    } = options;
    try {
      const data = await func(...args);
      return { error: undefined, data };
    } catch (error) {
      const message = getErrorFromBEResponse(error);
      if (onFailure) {
        if (typeof onFailure === 'string') {
          toastActions.error([onFailure, message]);
        } else {
          await onFailure(message);
        }
      }
      return { error, data: undefined };
    }
  };

/**
 * @template T
 * @param {string} entity
 * @param {(...any) => Promise<T>} func
 * @param {object} options
 * @param {string} [options.operation='load']
 * @param {((result: T, ...any) => any)|string} [options.onSuccess='Saved <entity> successfully.']
 * @param {((error: string) => any)|string} [options.onFailure='Unable to save <entity>"']
 * @param {string} [options.pathToRedirect='']
 */
export const writer =
  (entity, func, options = {}) =>
  async (...args) => {
    const {
      operation = 'save',
      onSuccess = `${capitalize(pastTense(operation))} ${entity} successfully.`,
      onFailure = `Unable to ${operation} ${entity}`,
      pathToRedirect = '',
    } = options;
    try {
      const result = await func(...args);
      if (onSuccess) {
        if (typeof onSuccess === 'string') {
          toastActions.success(onSuccess);
        } else {
          await onSuccess(result, ...args);
        }
      }
      // FIXME: using last argument dynamically is hard to type check interface
      // we need a better interface
      const [redirect = true] = args.slice(-1);
      if (redirect && pathToRedirect) {
        forceRedirect(pathToRedirect, 'replace');
      }
      return { data: result };
    } catch (error) {
      const message = getErrorFromBEResponse(error);
      if (onFailure) {
        if (typeof onFailure === 'string') {
          toastActions.error([onFailure, message]);
        } else {
          await onFailure(message);
        }
      }
      return { error };
    }
  };
