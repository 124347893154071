import './PosterView.css';

/**
 *
 * @param {object} p
 * @param {string} p.imageUrl
 * @param {string} p.imageAlt
 * @param {any} [p.header]
 * @param {any} [p.footer]
 */
function PosterView({ imageUrl, imageAlt, header, footer }) {
  return (
    <div className="PosterView">
      <div className="PosterView-header">{header}</div>
      <img src={imageUrl} alt={imageAlt} className="PosterView-poster" />
      <div className="PosterView-footer">{footer}</div>
    </div>
  );
}

export default PosterView;
