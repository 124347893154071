import cls from '~/helpers/cls';
import './IconButton.css';

const IconButton = (props) => {
  const {
    children,
    color = 'secondary',
    className: classes,
    onMouseDown = () => {},
    selected = false,
    size = 'md',
    ...rest
  } = props;

  const className = cls('IconButton', { color, selected, size }, classes);

  return (
    <button
      {...rest}
      className={className}
      onKeyDown={({ key }) => {
        if (!['Enter', ' '].includes(key)) return;
        onMouseDown(event);
      }}
      onMouseDown={onMouseDown}
      type="button"
    >
      {children}
    </button>
  );
};

export default IconButton;
