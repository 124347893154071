import { groupBy, map, reduce, orderBy } from 'lodash-es';

const groupByCode = (list) => {
  const codes = map(list, 'code');
  const group = groupBy(list, 'code');
  return reduce(codes, (acc, code) => ({ ...acc, [code]: group[code][0] }), {});
};

export const decodePlans = (plans) => {
  const cycledPlans = groupBy(plans, 'billingCycle');
  const cycles = Object.keys(cycledPlans);
  cycles.forEach((cycle) => {
    const sortedPlans = orderBy(cycledPlans[cycle], ['price']);
    sortedPlans.forEach((plan) => {
      const addons = orderBy(
        plan.addons,
        ['minQuantity', 'name'],
        ['desc', 'asc']
      );
      const groupedAddons = groupByCode(addons);
      plan.addons = groupedAddons;
    });
    const groupedPlans = groupByCode(sortedPlans);
    cycledPlans[cycle] = groupedPlans;
  });
  return cycledPlans;
};

export const makeBlankAccount = () => ({
  accountId: '',
  name: '',
  organization: '',
  phoneNumber: '',
  countryOfOperation: '',
  marketingSubscribed: false,
});

export const makeBlankPlan = () => ({
  addons: {},
  currency: '',
  description: '',
  name: '',
  price: 0,
});

export const makeBlankAddon = () => ({
  currency: '',
  code: '',
  description: '',
  maxQuantity: 0,
  minQuantity: 0,
  name: '',
  price: 0,
});
