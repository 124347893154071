import toSvgIcon from '~/components/icons/toSvgIcon';
import darkSvgUrl from '~/images/logo-dark.svg';
import darkMiniSvgUrl from '~/images/logo-dark-mini.svg';
import lightSvgUrl from '~/images/logo-light.svg';
import lightMiniSvgUrl from '~/images/logo-light-mini.svg';

const Dark = toSvgIcon(darkSvgUrl);
const DarkMini = toSvgIcon(darkMiniSvgUrl);
const Light = toSvgIcon(lightSvgUrl);
const LightMini = toSvgIcon(lightMiniSvgUrl);

const mapping = {
  normal: {
    icon: {
      dark: Dark,
      light: Light,
    },
    height: '56px',
    width: '160px',
  },
  mini: {
    icon: {
      dark: DarkMini,
      light: LightMini,
    },
    height: '36px',
    width: '36px',
  },
};

const Logo = (props) => {
  const { size = 'normal', color = 'dark' } = props;
  const { icon, ...rest } = mapping[size];
  const Icon = icon[color];
  return <Icon {...rest} />;
};

export default Logo;
