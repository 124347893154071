/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'preact/hooks';
import { filter, identity } from 'lodash-es';

import blockEvent from '~/helpers/blockEvent';
import textSearcher from '~/helpers/textSearcher';

import CountryFlag from '~/components/icons/CountryFlag';

import './CountryWithPhone.css';
import unfoldIcon from '~/images/unfold-icon.svg';

import cls from '~/helpers/cls';

import { countryInfo } from '../../enums';

import Field from '../inputs/Field';
import Popup from '../layout/Popup';
import Text from '../Text';
import Spacer from '../layout/Spacer';

const getCountryList = () =>
  countryInfo.map(({ code, name, phonePrefix }) => ({
    countryFlag: <CountryFlag country={code} />,
    code,
    countryName: name,
    countryPrefix: phonePrefix,
  }));

const CountryWithPhone = ({
  countryCode,
  disabled,
  onBlur = identity,
  onFocus = identity,
  setCountryCode,
  width,
}) => {
  const countryList = getCountryList();
  const [showPopup, setShowPopup] = useState(false);

  // Country Search Field
  const [filterText, setFilterText] = useState('');
  const [renderCycle, setRenderCycle] = useState(false);
  const forceRender = () => setRenderCycle(!renderCycle);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showPopup) {
      const closePopup = (event) => {
        onBlur(event);
        setShowPopup(false);
        window.removeEventListener('click', closePopup);
      };
      window.addEventListener('click', closePopup);
      return () => window.removeEventListener('click', closePopup);
    }
  }, [showPopup]);

  const setFilter = (event) => {
    const sanitized = event.target.value
      .replace(/[^a-zA-Z0-9\s+_-]/g, '')
      .replace(/\s+/g, ' ');
    setFilterText(sanitized);
    forceRender();
  };

  const textSearch = textSearcher(filterText);

  const filteredOptions = filter(
    countryList,
    ({ countryName, countryPrefix }) =>
      textSearch(countryName) || textSearch(countryPrefix)
  );

  const handleClick = (event) => {
    blockEvent(event);
    if (showPopup) {
      onBlur(event);
    } else {
      onFocus(event);
    }
    setShowPopup(!showPopup);
  };

  const RenderList = ({ option }) => {
    const { countryFlag, code, countryName, countryPrefix } = option;
    const classes = cls('CountryWithPhone-listItem', {});
    return (
      <div
        className={classes}
        key={`${code}::${countryName}`}
        onClick={(event) => {
          blockEvent(event);
          setCountryCode(countryPrefix);
          setFilterText('');
          setShowPopup(false);
          onBlur(event);
        }}
      >
        <div className="CountryWithPhone-listItem-countryNameWrapper">
          <div className="CountryWithPhone-listItem-countryFlag">
            {countryFlag}
          </div>
          <Spacer x={16} />
          <div className="CountryWithPhone-listItem-countryName">
            {countryName}
          </div>
        </div>
        <div className="CountryWithPhone-listItem-countryPrefix">
          <Text>{countryPrefix}</Text>
        </div>
      </div>
    );
  };

  const content = (options) => (
    <div className="CountryWithPhone-content" style={{ width }}>
      <Field
        disabled={disabled}
        className="CountryWithPhone-content-searchField"
        corner="sharp"
        placeholder="Search"
        ref={inputRef}
        type="text"
        onChange={setFilter}
        value={filterText}
      />
      <Spacer y={8} />
      {options.map((option, index) => (
        <RenderList key={index} option={option} />
      ))}
    </div>
  );

  return (
    <>
      <div className={cls('CountryWithPhone', { disabled })}>
        <div className="CountryWithPhone-flagIcon" onClick={handleClick}>
          <CountryFlag
            className="CountryWithPhone-flag"
            country={countryCode}
            disabled={disabled}
          />
          <Spacer x={3} />
          <img
            key={unfoldIcon}
            src={unfoldIcon}
            className={cls('CountryWithPhone-unfoldIcon', { disabled })}
            alt="toggle"
          />
        </div>
      </div>
      <Popup
        className="CountryWithPhone-popup"
        content={content(filteredOptions)}
        onClick={blockEvent}
        padding={false}
        position="bottom"
        show={showPopup}
      />
    </>
  );
};

export default CountryWithPhone;
