import { route } from 'preact-router';
import { updateRoute, getStates } from '../store';

export const setRoute = updateRoute;

export const push = (path) => {
  const {
    route: { blocked },
  } = getStates();
  if (!blocked) {
    return route(path);
  }
  return false;
};
export const replace = (path) => {
  const {
    route: { blocked },
  } = getStates();
  if (!blocked) {
    return route(path, true);
  }
  return false;
};
export const goBack = () => {
  const {
    route: { blocked },
  } = getStates();
  if (!blocked) {
    window.history.back();
    return true;
  }
  return false;
};

function onBeforeunload(event) {
  event.preventDefault();
}
export const block = () => {
  updateRoute({ blocked: true });
  window.addEventListener('beforeunload', onBeforeunload);
};

export const unblock = () => {
  updateRoute({ blocked: false });
  window.removeEventListener('beforeunload', onBeforeunload);
};
