import cls from '../helpers/cls';
import './Text.css';

const component = 'Text';

const variantProp = {
  h0: {
    color: 'secondary',
    font: 'title',
    size: 'xxxl',
    weight: 'boldest',
  },
  h1: {
    color: 'secondary',
    font: 'title',
    size: 'xxl',
    weight: 'boldest',
  },
  h2: {
    color: 'secondary',
    font: 'title',
    size: 'xl',
    weight: 'bold',
  },
  h3: {
    color: 'secondary',
    font: 'title',
    size: 'lg',
    weight: 'bold',
  },
  h4: {
    font: 'title',
    size: 'md',
    weight: 'bold',
  },
  h5: {
    size: 'sm',
    weight: 'bold',
  },
  h6: {
    size: 'ss',
    weight: 'bold',
  },
};

const defaultProps = {
  color: undefined,
  disabled: false,
  font: undefined,
  inline: false,
  nobreak: false,
  size: 'xs',
  weight: 'normal',
};

const styleProps = (variant, override) =>
  Object.fromEntries(
    Object.entries({ ...defaultProps, ...variantProp[variant] }).map(
      ([key, val]) => [key, override[key] || val]
    )
  );

/**
 * @param {object} props
 * @param {import('preact').ComponentChildren} props.children
 * @param {string} [props.className]
 * @param {'error' | 'greyed' | 'primary' | 'secondary' | 'tertiary'} [props.color]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.inline=false]
 * @param {'xxs' | 'xs' | 'small' | 'sm' | 'md' | 'medium' | 'lg' | 'xl' | 'xxl' | 'large' | 'xxxl'} [props.size]
 * @param {any} [props.style]
 * @param {'h0' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' } [props.variant]
 * @param {string} [props.weight='normal']
 */
const Text = (props) => {
  const {
    children = undefined,
    className = undefined,
    color = undefined,
    disabled = undefined,
    font = undefined,
    inline = undefined,
    nobreak = undefined,
    size = undefined,
    style = undefined,
    variant = undefined,
    weight = undefined,
  } = props;

  const classes = cls(
    component,
    styleProps(variant, {
      color,
      disabled: Boolean(disabled),
      font,
      inline,
      nobreak,
      size,
      weight,
    }),
    className
  );

  return (
    <span className={classes} style={style}>
      {children}
    </span>
  );
};

export default Text;
