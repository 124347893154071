const lookup = {
  add: 'added',
  cancel: 'cancelled',
  dismiss: 'dismissed',
  reset: 'reset',
  send: 'sent',
  ship: 'shipped',
  upload: 'uploaded',
};

export default (word) => {
  const cached = lookup[word];
  if (cached) return cached;
  const suffix = `${word.slice(-1)[0]}ed`.replace(/ee/, 'e');
  return `${word.slice(0, -1)}${suffix}`;
};
