import { getStates, updateAuth as genericUpdateAuth } from '../store';
import { isEqual } from 'lodash';
import { replace } from './route';
import promisify from '../helpers/promisify';
import * as toastActions from '../actions/toast';
import { isLogoutRequested, clearLogin, logout } from '../logout';
import { setCookie } from '../cookie';
import { listen } from '../broadcastChannel';

const computeAccountId = (
  { accessByAccountId = {}, accountId } = {},
  accountIdOverride
) => {
  const isAllowed = (id) =>
    id && (accessByAccountId[id] || accessByAccountId._ANY);
  if (isAllowed(accountIdOverride)) return accountIdOverride;
  if (isAllowed(accountId)) return accountId;
  const [defaultAccountId] = Object.keys(accessByAccountId || {}).filter(
    (id) => id !== '_ANY'
  );
  return defaultAccountId;
};

const updateAuth = (auth, prev) => {
  // update local store before doing global state (which may refresh the page)
  if (!isEqual(auth, prev)) localStorage.setItem('auth', JSON.stringify(auth));
  genericUpdateAuth(auth);
};

const handleTokens = ({
  accountIdOverride,
  accessToken,
  expiresAt,
  idTokenPayload = {},
}) => {
  const { sub, email } = idTokenPayload;

  const {
    connector = 'global',
    userId: carriyoUserId,
    name,
    picture,
    accessByAccountId,
    accessByTenantId,
  } = idTokenPayload[`${window.location.origin}/app_metadata`] || {};

  localStorage.setItem('idTokenPayload', JSON.stringify(idTokenPayload));

  const accountId = computeAccountId({ accessByAccountId }, accountIdOverride);

  const authMeta = {
    accessToken,
    expiresAt: Date.now() + expiresAt * 1000,
    carriyoUserId,
    name,
    email,
    picture,
    accessByAccountId,
    accessByTenantId,
    connector,
    auth0UserId: connector === 'global' ? sub : undefined,
    accountId,
  };

  updateAuth({ ...authMeta, authorizing: false });
  return authMeta;
};

export const refreshAuth = async (firstTime = false) => {
  const { getAuth0LoginData } = await import('../auth0-spa-client');
  const { idTokenPayload, accessToken, expiresAt } = await getAuth0LoginData(
    firstTime
  );
  return handleTokens({ accessToken, expiresAt, idTokenPayload });
};

export const checkAuthorization = async ({ accountId: accountIdOverride }) => {
  // 1. check for logout request
  if (isLogoutRequested()) {
    clearLogin();
  }

  // 2. check if we came from a login redirection
  const { hash, pathname, search } = window.location;
  const isFromLoginRedirection =
    hash.includes('access_token=') || hash.includes('error=');

  // if so we need to complete the login
  if (isFromLoginRedirection) {
    const { default: auth0Client } = await import('../auth0client');
    const auth0ParseHash = promisify(auth0Client.parseHash.bind(auth0Client));

    try {
      const {
        idTokenPayload,
        accessToken = '',
        expiresIn = 0,
      } = (await auth0ParseHash({ hash })) || {};

      const { accountId } = handleTokens({
        idTokenPayload,
        accessToken,
        accountIdOverride,
        expiresAt: expiresIn,
      });

      setCookie({
        lastLogin: Date.now(),
        lastLogout: undefined,
      });

      if (!accountId) {
        // FIXME: redirect to dashboard?
      }

      replace(`${pathname}${search}`);

      return true;
    } catch (error) {
      console.error(error);
      // @ts-ignore
      const { errorDescription = '' } = error;
      if (errorDescription.startsWith('User does not exist')) {
        toastActions.error(
          "Access denied. Your username has not been created with Carriyo yet. Please ask your organization's Carriyo admin to create user first and then try to login again.",
          Infinity
        );
      } else if (errorDescription.includes('`state` does not match.')) {
        // expired/invalid state. ask customer to login again
        return false;
      } else {
        toastActions.error(
          'Unexpected error during login. Please inform carriyo support.',
          Infinity
        );
      }
      return error;
    }
  }

  // 3. check state
  const { auth } = getStates();
  if (auth.accessToken) {
    const accountId = computeAccountId(auth, accountIdOverride);
    updateAuth({ ...auth, accountId, authorizing: false }, auth);
    return true;
  }

  // 4. check local storage
  const authFromLocalStorage = localStorage.getItem('auth') || '{}';
  const authMetaFromLocalStorage = JSON.parse(authFromLocalStorage);
  const now = Date.now();
  const { expiresAt = now } = authMetaFromLocalStorage;
  if (now < expiresAt) {
    const accountId = computeAccountId(
      authMetaFromLocalStorage,
      accountIdOverride
    );
    updateAuth(
      { ...authMetaFromLocalStorage, accountId, authorizing: false },
      authMetaFromLocalStorage
    );
    return true;
  }

  updateAuth({ authorizing: false });
  return false;
};

export const redirectToLoginPage = async (param = {}) => {
  const { default: auth0Client } = await import('../auth0client');
  auth0Client.authorize(param);
};

export const authorize = async () => {
  const authorized = await checkAuthorization();
  if (!authorized) redirectToLoginPage();
};

// --- Events ---

// real-time logout (Chrome)
listen('logoutRequest', () => logout(false));

// delayed logout (Safari)
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible' && isLogoutRequested()) {
    authorize();
  }
});
