import cls from '~/helpers/cls';
import './FileInput.css';

const FileInput = (props) => {
  const {
    children,
    className: classes,
    disabled,
    onChange: externalOnChange,
    ...rest
  } = props;
  const onChange = async (event) => {
    const { error } = (await externalOnChange(event)) || {};
    if (error) event.target.value = null;
  };
  const className = cls('FileInput', {}, classes);
  return (
    <div className={className} {...rest}>
      <input
        className="FileInput-input"
        disabled={disabled}
        onChange={onChange}
        type="file"
      />
      {children}
    </div>
  );
};

export default FileInput;
