import { useRef, useMemo, useState } from 'preact/hooks';
import { filter, find } from 'lodash-es';
import cls from '~/helpers/cls';
import ArrowDownwardIcon from '~/components/icons/ArrowDownwardIcon';
import Field from '~/components/inputs/Field';
import IconButton from '~/components/buttons/IconButton';
import InputLabel from '~/components/inputs/InputLabel';
import Menu from '~/components/layout/Menu';
import Text from '~/components/Text';
import './Select.css';

const DefaultRenderer = (props) => {
  const { disabled, option = {} } = props;
  return (
    <Text color="secondary" disabled={disabled || option.disabled}>
      {option.label}
    </Text>
  );
};

const sanitizeOptions = (baseOptions) => {
  const [option] = baseOptions;
  if (typeof option === 'object' || baseOptions.length === 0) {
    return baseOptions;
  }
  return baseOptions.map((value) => ({
    label: value,
    value,
  }));
};

const emptyOptions = [{ label: 'No options', disabled: true }];

const Select = (props) => {
  const {
    className = undefined,
    corner = 'round',
    disabled: rawDisabled = false,
    loading = false,
    onChange = undefined,
    onBlur: externalOnBlur = () => {},
    onFocus: externalOnFocus = () => {},
    options: externalOptions = [],
    placeholder = undefined,
    position = undefined,
    Renderer = DefaultRenderer,
    style = undefined,
    value = undefined,
  } = props;

  const options = useMemo(() => {
    const derivedOptions = sanitizeOptions(externalOptions);
    return derivedOptions.length ? derivedOptions : emptyOptions;
  }, [externalOptions]);

  const disabled = Boolean(rawDisabled || loading);
  const partialDisable = rawDisabled === 'partial';

  const [filterText, setFilterText] = useState('');
  const [focused, setFocused] = useState(false);
  const [renderCycle, setRenderCycle] = useState(false);
  const forceRender = () => setRenderCycle(!renderCycle);
  const inputRef = useRef(null);

  const setFilter = (event) => {
    const sanitized = event.target.value
      .replace(/[^a-zA-Z0-9\s+_-]/g, '')
      .replace(/\s+/g, ' ');
    setFilterText(sanitized);
    forceRender();
  };

  const onBlur = (event) => {
    setFilterText('');
    setFocused(false);
    externalOnBlur(event);
  };

  const onFocus = (event) => {
    setFilterText('');
    setFocused(true);
    externalOnFocus(event);
  };

  const renderOption = (option) => {
    const { label, value: key } = option;
    const selected = value !== undefined && key === value;
    const classes = cls('Select-option', { selected });
    const change = (event) => {
      event.target.value = key;
      onChange(event);
      onBlur(event);
    };
    return (
      <button className={classes} key={`${key}::${label}`} onMouseDown={change}>
        <Renderer disabled={disabled} option={option} />
      </button>
    );
  };

  const selectedOption = find(options, { value });

  const selection = focused ? null : (
    <Renderer
      disabled={disabled && (loading || !partialDisable)}
      option={selectedOption}
    />
  );

  const escapedFilterText = filterText.replace(/\+/g, '\\+');
  const filterTest = RegExp.prototype.test.bind(
    new RegExp(escapedFilterText, 'i')
  );
  const filteredOptions = filter(
    options,
    ({ label, value }) => filterTest(label) || filterTest(value)
  );

  const classes = cls('Select', { corner }, className);

  const toggleFocus = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (document.activeElement === inputRef.current.base) {
      inputRef.current.base.blur();
    } else {
      inputRef.current.base.focus();
    }
  };

  return (
    <div className={classes}>
      <div className="Select-inputWrapper">
        <div className="Select-field">
          <Field
            disabled={disabled}
            loading={loading}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={setFilter}
            placeholder={selectedOption ? '' : placeholder}
            ref={inputRef}
            style={style}
            type="text"
            value={filterText}
          />
        </div>
        <div className="Select-selection">{selection}</div>
        {!disabled && (
          <IconButton
            className={cls('Select-indicator', { focused })}
            onMouseDown={toggleFocus}
          >
            <ArrowDownwardIcon />
          </IconButton>
        )}
      </div>
      {!disabled && (
        <Menu
          options={filteredOptions}
          position={position}
          render={renderOption}
          show={focused}
        />
      )}
    </div>
  );
};

const Wrapper = (props) => (
  <InputLabel {...props} Input={Select} outline width="full" />
);

export default Wrapper;
