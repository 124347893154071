import { useEffect } from 'preact/hooks';
import { Router } from 'preact-router';
import Redirect from './Redirect';

// -- custom --
// import { useGlobalStates } from '../../store';
import Layout from './Layout';
import routes from '../routes/routes';
// import { getPermissionsForRoute } from '../../permissions';
import redirects from './redirects';
// import * as routeActions from '../../actions/route';

const RouteComponent = (props) => {
  const { route, url, matches } = props;

  let title;
  if (typeof route.title === 'function') {
    title = route.title(props);
  } else {
    title = (route.title || '').replace(
      /:([^\b]+)/g,
      (m, name) => matches?.[name] ?? m
    );
  }

  useEffect(() => {
    document.title = ['Account Management', title].join(' | ');
  }, []);

  return (
    <Layout
      component={route.Component}
      mobileComponent={route.MobileComponent}
      route={{
        ...route,
        url,
        matches,
        title,
      }}
    />
  );
};

const WrappedRouter = () => (
  <Router>
    {redirects.map(({ id, path, to }) => (
      <Redirect key={`redirects-${id}`} path={path} to={to} />
    ))}
    {routes.map((route) => (
      <RouteComponent
        key={route.path}
        path={route.path}
        route={route}
        default={route.default}
      />
    ))}
  </Router>
);

export default WrappedRouter;
