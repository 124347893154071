import cls from '~/helpers/cls';
import './Tag.css';

const Tag = (props) => {
  const {
    className = undefined,
    children = undefined,
    color = 'secondary',
    style = undefined,
  } = props;
  const classes = cls('Tag', { color }, className);
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export default Tag;
