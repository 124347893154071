import cookies from 'js-cookie';
import { get } from 'lodash';

const tldDomain = window.location.hostname.split('.').slice(-2).join('.'); // carriyo.com or localhost
const baseEnv = /** @type {string}*/ (import.meta.env.VITE_APP_BASE_ENV);

export function getCookie(key = tldDomain, domain = tldDomain) {
  return get(JSON.parse(cookies.get(key, { domain }) || '{}'), [baseEnv]);
}

export function setCookie(partial, key = tldDomain, domain = tldDomain) {
  const cookieValue = JSON.parse(cookies.get(key, { domain }) || '{}');
  cookies.set(
    key,
    JSON.stringify({
      ...cookieValue,
      [baseEnv]: {
        ...(cookieValue[baseEnv] || {}),
        ...partial,
      },
    }),
    { domain }
  );
}
