import { useEffect } from 'preact/hooks';

/**
 * @param {() => any} asyncEffect
 * @param {import('preact/hooks').Inputs} [dependencies]
 * @template Type
 */
function useAsyncEffect(asyncEffect, dependencies = []) {
  useEffect(() => {
    asyncEffect();
  }, dependencies);
}

export default useAsyncEffect;
