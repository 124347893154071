const DEFAULT_ERROR_MESSAGE = 'Something went wrong! Please try again.';

export default (err) => {
  if (!import.meta.env.IS_PROD) {
    // TODO: add logging
    console.error(err);
  }
  return (
    err?.response?.data?.message ||
    err?.response?.data?.error ||
    err?.response?.data?.errors ||
    (typeof err.response?.data === 'string' ? err.response?.data : undefined) ||
    err.message ||
    err.error ||
    DEFAULT_ERROR_MESSAGE
  );
};
